import React, { useState } from "react";
import axios from "axios";

// Access the environment variable
const API_KEY = import.meta.env.internal_api_key;

const ContactForm = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      // Push event to data layer
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "contact_form",
        formType: "contact",
      });

      // Prepare the request body
      const requestBody = {
        mail: {
          to_email: "ys@intent.de",
        },
        content: {
          subject: "Kontakt Formular",
          content: `E-Mail: ${email} <br /> Inhalt: ${message}`,
        },
      };
      console.log(
        "Sending request with body:",
        JSON.stringify(requestBody, null, 2),
      );
      // Send request to your FastAPI endpoint
      const response = await axios.post(
        `${import.meta.env.VITE_APP_API}/api/email/send-generic`,
        requestBody,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "api-key": API_KEY,
          },
        },
      );
      console.log("Response:", response);
      if (response.status === 200) {
        alert("Nachricht erfolgreich gesendet!");
        setEmail("");
        setMessage("");
      } else {
        alert(
          "Es gab einen Fehler beim Senden der Nachricht. Bitte versuchen Sie es später erneut.",
        );
      }
    } catch (error) {
      console.error("Error:", error);
      alert(
        "Es gab einen Fehler beim Senden der Nachricht. Bitte versuchen Sie es später erneut.",
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <section className="max-h-full bg-white dark:bg-gray-900">
      <div className="mx-auto mb-12 max-w-screen-md px-4 py-8 lg:py-16">
        <h2 className="mb-4 text-4xl font-extrabold tracking-tight text-gray-900 dark:text-white">
          Kontakt
        </h2>
        <p className="mb-4 font-light text-gray-500 dark:text-gray-400 sm:text-xl lg:mb-16">
          Wir sind hier, um Ihnen zu helfen. Falls Sie Rückfragen haben, ein
          Problem bei der Nutzung unserer Dienstleistungen auftritt oder Sie
          spezielle Anforderungen haben, zögern Sie bitte nicht, uns zu
          kontaktieren. <br />
          <br />
          Unser Team steht Ihnen gerne zur Verfügung, um Ihre Anliegen schnell
          und effizient zu klären. Wir freuen uns darauf, von Ihnen zu hören!
        </p>
        <form onSubmit={handleSubmit} className="space-y-8">
          <div>
            <label
              htmlFor="email"
              className="mb-2 block text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              E-Mail
            </label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 shadow-sm focus:border-primary-500 focus:ring-primary-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:shadow-sm-light dark:focus:border-primary-500 dark:focus:ring-primary-500"
              placeholder="name@beispiel.de"
              required
            />
          </div>
          <div className="sm:col-span-2">
            <label
              htmlFor="message"
              className="mb-2 block text-sm font-medium text-gray-900 dark:text-gray-400"
            >
              Nachricht
            </label>
            <textarea
              id="message"
              rows="6"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 shadow-sm focus:border-primary-500 focus:ring-primary-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-primary-500 dark:focus:ring-primary-500"
              placeholder="Hier ist viel Platz für Ihre Nachricht..."
              required
            ></textarea>
          </div>
          <button type="submit" className="btn" disabled={isSubmitting}>
            {isSubmitting ? "Wird gesendet..." : "Nachricht absenden"}
          </button>
        </form>
      </div>
    </section>
  );
};

export default ContactForm;
