import React from "react";
import { Outlet } from "react-router-dom";

function AccountSettings() {
  return (
    <p className="text-center text-2xl font-bold text-primary-600 sm:text-4xl">
      Settings 👋
      <Outlet />
    </p>
  );
}

export default AccountSettings;
