import { useParams, useSearchParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { companiesDetailsQuery } from "./companies.loader.ts";
import CompaniesContent from "./company-content.tsx";
import LeadRating from "@/routes/companies/company-details/lead-ratings.tsx";
import CompanySessions from "@/routes/companies/company-details/company-sessions.tsx";

function CompanyDetails() {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const { data: companiesDetails } = useQuery(
    companiesDetailsQuery(searchParams, params),
  );
  console.log({ companiesDetails });
  if (!companiesDetails) return null;
  return (
    <div className="h-full flex-1 overflow-auto border-l bg-white">
      <div className="flex flex-col gap-4 px-4 py-2">
        <CompaniesContent companiesDetails={companiesDetails} />
        <LeadRating companiesDetails={companiesDetails} />
        <CompanySessions companiesDetails={companiesDetails} />
      </div>
    </div>
  );
}

export default CompanyDetails;
