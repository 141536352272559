import { Button } from "@/components/ui/button";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "@/components/ui/drawer";
import type { ReactNode } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";

interface Props {
  trigger: ReactNode;
}

const formSchema = z.object({
  label: z.string().nonempty("Label is required"),
  session: z.object({
    utm_medium: z.string().optional(),
    utm_campaign: z.string().optional(),
    utm_term: z.string().optional(),
    utm_content: z.string().optional(),
    utm_campaign_id: z.string().optional(),
    session_duration: z.string().optional(),
    session_time: z.string().optional(),
  }),
  user_data: z.object({
    document_location: z.string().url("Must be a valid URL").optional(),
    document_title: z.string().optional(),
    scroll_depth: z.string().optional(),
  }),
  company: z.object({
    name: z.string().nonempty("Company name is required"),
    postal: z.string().optional(),
    city: z.string().optional(),
    country: z.string().optional(),
    nace: z.object({
      code: z.string().optional(),
      section: z.string().optional(),
      division: z.string().optional(),
      activity: z.string().optional(),
    }),
  }),
});

function SegmentsDrawer({ trigger }: Props) {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      label: "",
      session: {
        utm_medium: "",
        utm_campaign: "",
        utm_term: "",
        utm_content: "",
        utm_campaign_id: "",
        session_duration: "",
        session_time: "",
      },
      user_data: {
        document_location: "",
        document_title: "",
        scroll_depth: "",
      },
      company: {
        name: "",
        postal: "",
        city: "",
        country: "",
        nace: {
          code: "",
          section: "",
          division: "",
          activity: "",
        },
      },
    },
  });

  // 2. Define a submit handler.
  function onSubmit(values: z.infer<typeof formSchema>) {
    // Do something with the form values.
    // ✅ This will be type-safe and validated.
    console.log(values);
  }

  return (
    <Drawer direction="left">
      <DrawerTrigger asChild>{trigger}</DrawerTrigger>
      <DrawerContent className="bg-background fixed inset-y-0 z-50 w-72 border-r">
        <Form {...form}>
          <div className="flex h-full flex-col">
            <DrawerHeader className="border-b">
              <DrawerTitle>New Segment</DrawerTitle>
              <DrawerDescription>
                Create your advanced companies filter.
              </DrawerDescription>
            </DrawerHeader>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className="grow space-y-8 overflow-y-scroll p-4"
            >
              {/* Label Field */}
              <FormField
                control={form.control}
                name="label"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Label</FormLabel>
                    <FormControl>
                      <Input placeholder="Enter label" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              {/* Session Fields */}
              <fieldset className="space-y-4">
                <legend className="text-primary text-sm font-semibold leading-6">
                  Session
                </legend>

                <FormField
                  control={form.control}
                  name="session.utm_medium"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>UTM Medium</FormLabel>
                      <FormControl>
                        <Input placeholder="Enter UTM Medium" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="session.utm_campaign"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>UTM Campaign</FormLabel>
                      <FormControl>
                        <Input placeholder="Enter UTM Campaign" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="session.utm_term"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>UTM Term</FormLabel>
                      <FormControl>
                        <Input placeholder="Enter UTM Term" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="session.utm_content"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>UTM Content</FormLabel>
                      <FormControl>
                        <Input placeholder="Enter UTM Content" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="session.utm_campaign_id"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>UTM Campaign ID</FormLabel>
                      <FormControl>
                        <Input placeholder="Enter UTM Campaign ID" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="session.session_duration"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Session Duration</FormLabel>
                      <FormControl>
                        <Input
                          placeholder="Enter Session Duration"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="session.session_time"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Session Time</FormLabel>
                      <FormControl>
                        <Input placeholder="Enter Session Time" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </fieldset>

              {/* User Data Fields */}
              <fieldset className="space-y-4">
                <legend className="text-primary text-sm font-semibold leading-6">
                  User Data
                </legend>

                <FormField
                  control={form.control}
                  name="user_data.document_location"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Document Location</FormLabel>
                      <FormControl>
                        <Input
                          placeholder="Enter Document Location"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="user_data.document_title"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Document Title</FormLabel>
                      <FormControl>
                        <Input placeholder="Enter Document Title" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="user_data.scroll_depth"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Scroll Depth</FormLabel>
                      <FormControl>
                        <Input placeholder="Enter Scroll Depth" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </fieldset>

              {/* Company Fields */}
              <fieldset className="space-y-4">
                <legend className="text-primary text-sm font-semibold leading-6">
                  Company
                </legend>

                <FormField
                  control={form.control}
                  name="company.name"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Company Name</FormLabel>
                      <FormControl>
                        <Input placeholder="Enter Company Name" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="company.postal"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Postal</FormLabel>
                      <FormControl>
                        <Input placeholder="Enter Postal Code" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="company.city"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>City</FormLabel>
                      <FormControl>
                        <Input placeholder="Enter City" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="company.country"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Country</FormLabel>
                      <FormControl>
                        <Input placeholder="Enter Country" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                {/* NACE Fields */}
                <fieldset className="space-y-4">
                  <legend className="text-primary text-sm font-semibold leading-6">
                    NACE
                  </legend>

                  <FormField
                    control={form.control}
                    name="company.nace.code"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>NACE Code</FormLabel>
                        <FormControl>
                          <Input placeholder="Enter NACE Code" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="company.nace.section"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>NACE Section</FormLabel>
                        <FormControl>
                          <Input placeholder="Enter NACE Section" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="company.nace.division"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>NACE Division</FormLabel>
                        <FormControl>
                          <Input placeholder="Enter NACE Division" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="company.nace.activity"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>NACE Activity</FormLabel>
                        <FormControl>
                          <Input placeholder="Enter NACE Activity" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </fieldset>
              </fieldset>
            </form>
            <DrawerFooter className="border-t">
              <Button type="submit">Save</Button>
              <DrawerClose asChild>
                <Button variant="outline">Cancel</Button>
              </DrawerClose>
            </DrawerFooter>
          </div>
        </Form>
      </DrawerContent>
    </Drawer>
  );
}

export default SegmentsDrawer;
