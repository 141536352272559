import { CompaniesDetails } from "./companies.loader.ts";
import { Accordion, Timeline } from "flowbite-react";
import {
  format,
  formatDistanceToNow,
  formatDuration,
  intervalToDuration,
} from "date-fns";
import { de } from "date-fns/locale";

const accordionTitleTheme = {
  heading: "-mb-px flex flex-1 items-center gap-2 font-medium",
  flush: {
    off: "-mb-px flex-row-reverse border-b py-3 flex flex-1 items-center gap-2 px-4 font-medium transition-all",
  },
  open: {
    on: "bg-white",
  },
  arrow: {
    open: {
      off: "transition-all",
      on: "rotate-180 transition-all",
    },
  },
};
const contentAccordionTheme = {
  base: "first:rounded-t-lg last:rounded-b-lg ",
};
const customTimelineTheme = {
  root: {
    direction: {
      vertical: "relative ",
    },
  },
  item: {
    root: {
      vertical: "relative flex gap-x-3 pr-2.5",
    },
    point: {
      vertical: "h-6 left-0 top-0 flex w-6 justify-center bg-[#ffffff]",
      marker: {
        base: {
          vertical:
            "h-1.5 w-1.5 rounded-full bg-[#e2e8f080] ring-1 ring-[#64748b80]",
        },
      },
    },
  },
};

function CompanySessions({
  companiesDetails,
}: {
  companiesDetails: CompaniesDetails;
}) {
  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  const getDisplayNameForUTM = (key: string) => {
    const utmMap: { [key: string]: string } = {
      utm_campaign: "Kampagne",
      utm_term: "Term",
      utm_content: "Content",
      utm_campaign_id: "Kampagne ID",
    };
    return utmMap[key] || capitalizeFirstLetter(key.replace("utm_", ""));
  };

  type UTMItem = {
    [key: string]: string | null | undefined;
  };

  const getAdditionalUTMParams = (item: UTMItem) => {
    return Object.entries(item)
      .filter(
        ([key, value]) =>
          key.startsWith("utm_") &&
          key !== "utm_source" &&
          key !== "utm_medium" &&
          value !== null &&
          value !== undefined &&
          value !== "",
      )
      .sort(([a], [b]) => {
        const order = [
          "utm_campaign",
          "utm_term",
          "utm_content",
          "utm_campaign_id",
        ];
        return order.indexOf(a) - order.indexOf(b);
      });
  };

  return (
    <>
      {companiesDetails &&
        companiesDetails.sessions &&
        companiesDetails.sessions.map((item, index) => {
          const additionalUTMParams = getAdditionalUTMParams(
            item as unknown as UTMItem,
          );
          return (
            <Accordion
              className={`overflow-hidden rounded-lg border-none bg-white shadow-sm ring-1 ring-gray-700/10 ${index > 0 ? "mt-0" : ""}`}
              key={index}
            >
              <Accordion.Panel key={index}>
                <Accordion.Title theme={accordionTitleTheme}>
                  <div className="text-primary flex h-6 w-6 shrink-0 items-center justify-center rounded-full border border-white border-opacity-20 bg-[#2463eb1a] text-xs text-[#2463eb] shadow-[0_0_0_1px_rgb(148,182,255),0_1px_2px_rgba(26,19,161,0.5)]">
                    {item.user_agent_category === "mobile" ? (
                      <svg
                        className="h-4 w-4"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fillRule="evenodd"
                          d="M5 4a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V4Zm12 12V5H7v11h10Zm-5 1a1 1 0 1 0 0 2h.01a1 1 0 1 0 0-2H12Z"
                          clipRule="evenodd"
                        />
                      </svg>
                    ) : (
                      <svg
                        className="h-4 w-4"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M12 15v5m-3 0h6M4 11h16M5 15h14a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v9a1 1 0 0 0 1 1Z"
                        />
                      </svg>
                    )}
                  </div>
                  <div className="flex flex-1 items-center justify-between">
                    <h3 className="text-xs font-semibold tracking-tight text-black">
                      {capitalizeFirstLetter(item.user_agent_category)} Session
                    </h3>
                    <p className="text-xs font-normal text-gray-400">
                      <time
                        dateTime={item.timestamp}
                        className="flex-none text-xs text-gray-600"
                      >
                        {format(
                          new Date(item.timestamp),
                          "dd. MMMM yyyy, HH:mm 'Uhr'",
                          { locale: de },
                        )}
                      </time>
                    </p>
                  </div>
                </Accordion.Title>
                <Accordion.Content theme={contentAccordionTheme}>
                  <div className="p-4">
                    <div className="space-y-4">
                      <dl className="-mx-4 grid grid-cols-1 gap-px overflow-hidden rounded-lg py-0 sm:grid-cols-2 lg:grid-cols-3">
                        <div className="flex flex-wrap items-baseline justify-between gap-y-0.5 pl-4">
                          <dt className="text-xs font-medium text-gray-400">
                            {" "}
                            Quelle / Medium{" "}
                          </dt>
                          <dd className="w-full flex-none truncate text-xs font-medium tracking-tight">
                            <span>
                              {capitalizeFirstLetter(item.utm_source)} /{" "}
                              {capitalizeFirstLetter(item.utm_medium)}
                            </span>
                          </dd>
                        </div>

                        <div className="grid flex-grow">
                          {additionalUTMParams.length > 0 && (
                            <div className="grid flex-grow grid-cols-3 gap-4 border-l pl-4">
                              {additionalUTMParams.map(([key, value]) => (
                                <div key={key} className="flex flex-col">
                                  <dt className="text-xs font-medium text-gray-400">
                                    {getDisplayNameForUTM(key)}
                                  </dt>
                                  <dd className="mt-1 truncate text-xs font-medium tracking-tight">
                                    {value as string}
                                  </dd>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>

                        {item.session_duration > 0 && (
                          <div className="fl1ex-shrink-0 order-last w-2/4 border-l pl-4">
                            <dt className="text-xs font-medium text-gray-400">
                              Session Dauer
                            </dt>
                            <dd className="mt-1 truncate text-xs font-medium tracking-tight">
                              {formatDuration(
                                intervalToDuration({
                                  start: 0,
                                  end: item.session_duration * 1000,
                                }),
                                { locale: de },
                              )}
                            </dd>
                          </div>
                        )}
                      </dl>
                      <div
                        data-orientation="horizontal"
                        role="separator"
                        className="h-px w-full shrink-0 bg-[#e2e8f0]"
                      ></div>
                      <Timeline theme={customTimelineTheme}>
                        {item.user_data.map((timelineItem, timelineIndex) => (
                          <Timeline.Item
                            key={timelineIndex}
                            className={`${timelineIndex !== 0 ? "mt-6" : ""} relative flex gap-x-3 pr-2.5`}
                          >
                            {timelineIndex !== item.user_data.length - 1 && (
                              <div className="absolute -bottom-6 left-0 top-6 flex w-6 justify-center">
                                <div className="w-px bg-[#e2e8f0]"></div>
                              </div>
                            )}
                            <Timeline.Point className="relative left-0 top-0 flex h-6 w-6 items-center justify-center bg-white"></Timeline.Point>
                            <Timeline.Content className="w-2/3 truncate">
                              <div className="flex flex-col gap-2 truncate">
                                <p className="flex-auto truncate text-xs leading-5">
                                  <svg
                                    className="mr-1 inline h-4 w-4"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                  >
                                    <path
                                      stroke="currentColor"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                      d="M10 3v4a1 1 0 0 1-1 1H5m14-4v16a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V7.914a1 1 0 0 1 .293-.707l3.914-3.914A1 1 0 0 1 9.914 3H18a1 1 0 0 1 1 1Z"
                                    />
                                  </svg>
                                  <a
                                    className="m-0 inline h-auto items-center justify-center truncate rounded-md p-0 text-xs font-medium leading-5 text-[#020817] underline-offset-4 transition-colors hover:underline focus-visible:outline-none disabled:pointer-events-none disabled:opacity-50"
                                    data-loading="false"
                                    href={timelineItem.document_location}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <span className="truncate">
                                      {timelineItem.document_location.replace(
                                        /^https?:\/\/[^/]+/,
                                        "",
                                      )}
                                    </span>
                                  </a>
                                </p>
                                {(timelineItem.time_spent > 0 ||
                                  (item.user_data.length > 1 &&
                                    timelineIndex !==
                                      item.user_data.length - 1)) && (
                                  <div className="inline-flex shrink-0 items-center text-xs text-gray-400">
                                    <svg
                                      className="mr-1 h-4 w-4 text-gray-400"
                                      aria-hidden="true"
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                    >
                                      <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M12 8v4l3 3m6-3a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                                      />
                                    </svg>
                                    <span>
                                      {item.user_data.length > 1 &&
                                      timelineIndex !==
                                        item.user_data.length - 1
                                        ? formatDuration(
                                            intervalToDuration({
                                              start: new Date(
                                                timelineItem.timestamp,
                                              ),
                                              end: new Date(
                                                item.user_data[
                                                  item.user_data.length - 1
                                                ].timestamp,
                                              ),
                                            }),
                                            { locale: de },
                                          )
                                        : formatDuration(
                                            intervalToDuration({
                                              start: 0,
                                              end:
                                                timelineItem.time_spent * 1000,
                                            }),
                                            { locale: de },
                                          )}
                                    </span>
                                    {timelineItem.timestamp && (
                                      <>
                                        <div
                                          data-orientation="horizontal"
                                          role="separator"
                                          className="bg-border mx-2 h-[10px] w-px shrink-0"
                                        ></div>
                                        <div>
                                          <button data-state="closed">
                                            {formatDistanceToNow(
                                              new Date(timelineItem.timestamp),
                                              {
                                                locale: de,
                                                addSuffix: true,
                                              },
                                            )}
                                          </button>
                                        </div>
                                      </>
                                    )}
                                  </div>
                                )}
                              </div>
                            </Timeline.Content>
                          </Timeline.Item>
                        ))}
                      </Timeline>
                    </div>
                  </div>
                </Accordion.Content>
              </Accordion.Panel>
            </Accordion>
          );
        })}
    </>
  );
}

export default CompanySessions;
