import React from "react";
import { Card, Timeline } from "flowbite-react";

function AccountApiDetails() {
  return (
    <div className="card">
      <div className="card-header mb-5" id="external_services_manage_api">
        <h3 className="card-title">Public API Key</h3>
        <div className="flex items-center gap-2">
          <label className="switch switch-sm">
            <span className="switch-label">Pause</span>
            <input name="check" type="checkbox" value="1" />
          </label>
        </div>
      </div>
      <div className="card-body lg:py-7.5 lg:gap-7.5 grid gap-5">
        <div className="flex flex-wrap items-baseline gap-2.5 lg:flex-nowrap">
          <label className="form-label max-w-56 font-normal text-gray-800">
            API Key
          </label>
          <label className="input">
            <input
              placeholder="Right icon"
              type="text"
              value="abc123xyz456sample789key000"
            />
            <button className="btn btn-icon">
              <i className="ki-filled ki-copy"></i>
            </button>
          </label>
        </div>
        <div className="user-access-bg flex grow items-center justify-between gap-2 rounded-xl border border-gray-200 bg-[length:650px] bg-no-repeat p-5 [background-position:220%_44%]">
          <div className="flex items-center gap-4">
            <div className="relative size-[50px] shrink-0">
              <svg
                className="stroke-brand-clarity fill-brand-light h-full w-full"
                fill="none"
                height="48"
                viewBox="0 0 44 48"
                width="44"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16 2.4641C19.7128 0.320509 24.2872 0.320508 28 2.4641L37.6506 8.0359C41.3634 10.1795 43.6506 14.141 43.6506 
 18.4282V29.5718C43.6506 33.859 41.3634 37.8205 37.6506 39.9641L28 45.5359C24.2872 47.6795 19.7128 47.6795 16 45.5359L6.34937 
 39.9641C2.63655 37.8205 0.349365 33.859 0.349365 29.5718V18.4282C0.349365 14.141 2.63655 10.1795 6.34937 8.0359L16 2.4641Z"
                  fill=""
                ></path>
                <path
                  d="M16.25 2.89711C19.8081 0.842838 24.1919 0.842837 27.75 2.89711L37.4006 8.46891C40.9587 10.5232 43.1506 14.3196 43.1506 
 18.4282V29.5718C43.1506 33.6804 40.9587 37.4768 37.4006 39.5311L27.75 45.1029C24.1919 47.1572 19.8081 47.1572 16.25 45.1029L6.59937 
 39.5311C3.04125 37.4768 0.849365 33.6803 0.849365 29.5718V18.4282C0.849365 14.3196 3.04125 10.5232 6.59937 8.46891L16.25 2.89711Z"
                  stroke=""
                ></path>
              </svg>
              <div className="absolute left-2/4 top-2/4 -translate-x-2/4 -translate-y-2/4 leading-none">
                <i className="ki-filled ki-security-user text-brand text-xl"></i>
              </div>
            </div>
            <div className="flex flex-col gap-1.5">
              <div className="flex items-center gap-2.5">
                <a
                  className="hover:text-primary-active text-base font-medium text-gray-900"
                  href="#"
                >
                  User Access
                </a>
                <span className="badge badge-sm badge-outline">
                  16 days left
                </span>
              </div>
              <div className="form-info font-normal text-gray-800">
                This API key can only access
                <a className="link" href="https://keenthemes.com/">
                  @keenthemes
                </a>
                <br />
                Secure access with a unique API key for enhanced functionality.
              </div>
            </div>
          </div>
          <div className="flex items-center gap-1.5">
            <button className="btn btn-sm btn-dark">Renew Plan</button>
            <a className="btn btn-sm btn-clear btn-light" href="#">
              Docs
            </a>
          </div>
        </div>
        <p className="text-2sm text-gray-800">
          Unlock the full potential of your application with our API, a secure
          gateway facilitating seamless integration, empowering developers to
          create innovative and dynamic experiences effortlessly.
        </p>
      </div>
    </div>
  );
}

export default AccountApiDetails;
