import React from "react";
import PricingTable from "./PricingTable";
const PricingFeatures = () => {
  return (
    <section className="border-b border-gray-200 bg-white dark:border-gray-700 dark:bg-gray-900">
      <div className="mx-auto max-w-screen-xl px-4 py-8 lg:px-6 lg:py-16">
        <h2 className="mb-8 text-center text-4xl font-extrabold tracking-tight text-gray-900 dark:text-white">
          Preisübersicht
        </h2>
        <p className="mb-12 text-center font-light text-gray-500 dark:text-gray-400 sm:text-xl">
          Bei Webmetic konzentrieren wir uns darauf, anonymen Website-Traffic
          durch innovative <br />
          Technologie und datengesteuerte Einblicke in wertvolle
          Geschäftschancen zu verwandeln.
        </p>

        <PricingTable />
        <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
          <div className="flex">
            <div className="mr-4 flex h-12 w-12 shrink-0 items-center justify-center rounded bg-white shadow dark:bg-gray-700">
              <svg
                className="h-7 w-7 text-gray-900 dark:text-gray-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M3 3a1 1 0 000 2v8a2 2 0 002 2h2.586l-1.293 1.293a1 1 0 101.414 1.414L10 15.414l2.293 2.293a1 1 0 001.414-1.414L12.414 15H15a2 2 0 002-2V5a1 1 0 100-2H3zm11 4a1 1 0 10-2 0v4a1 1 0 102 0V7zm-3 1a1 1 0 10-2 0v3a1 1 0 102 0V8zM8 9a1 1 0 00-2 0v2a1 1 0 102 0V9z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </div>
            <div>
              <h3 className="mb-1 text-xl font-bold text-gray-900 dark:text-white">
                Unternehmensidentifikation
              </h3>
              <p className="font-light text-gray-500 dark:text-gray-400">
                Identifizieren Sie die Unternehmen hinter Ihren
                Website-Besuchern mit unübertroffener Genauigkeit.
              </p>
            </div>
          </div>
          <div className="flex">
            <div className="mr-4 flex h-12 w-12 shrink-0 items-center justify-center rounded bg-white shadow dark:bg-gray-700">
              <svg
                className="h-7 w-7 text-gray-900 dark:text-gray-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4 4a2 2 0 012-2h8a2 2 0 012 2v12a1 1 0 110 2h-3a1 1 0 01-1-1v-2a1 1 0 00-1-1H9a1 1 0 00-1 1v2a1 1 0 01-1 1H4a1 1 0 110-2V4zm3 1h2v2H7V5zm2 4H7v2h2V9zm2-4h2v2h-2V5zm2 4h-2v2h2V9z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </div>
            <div>
              <h3 className="mb-1 text-xl font-bold text-gray-900 dark:text-white">
                Verhaltensanalyse
              </h3>
              <p className="font-light text-gray-500 dark:text-gray-400">
                Gewinnen Sie tiefe Einblicke in das Verhalten, die Präferenzen
                und Bedürfnisse Ihrer Besucher.
              </p>
            </div>
          </div>
          <div className="flex">
            <div className="mr-4 flex h-12 w-12 shrink-0 items-center justify-center rounded bg-white shadow dark:bg-gray-700">
              <svg
                className="h-7 w-7 text-gray-900 dark:text-gray-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M7 2a2 2 0 00-2 2v12a2 2 0 002 2h6a2 2 0 002-2V4a2 2 0 00-2-2H7zm3 14a1 1 0 100-2 1 1 0 000 2z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </div>
            <div>
              <h3 className="mb-1 text-xl font-bold text-gray-900 dark:text-white">
                Lead-Bewertung
              </h3>
              <p className="font-light text-gray-500 dark:text-gray-400">
                Priorisieren Sie automatisch Ihre vielversprechendsten Leads
                basierend auf Engagement-Levels.
              </p>
            </div>
          </div>
          <div className="flex">
            <div className="mr-4 flex h-12 w-12 shrink-0 items-center justify-center rounded bg-white shadow dark:bg-gray-700">
              <svg
                className="h-7 w-7 text-gray-900 dark:text-gray-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z"></path>
              </svg>
            </div>
            <div>
              <h3 className="mb-1 text-xl font-bold text-gray-900 dark:text-white">
                Nahtlose Integration
              </h3>
              <p className="font-light text-gray-500 dark:text-gray-400">
                Verbinden Sie Webmetic mühelos mit Ihren bestehenden CRM- und
                Marketing-Tools.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PricingFeatures;
