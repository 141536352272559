import { Outlet, Params, useParams, useSearchParams } from "react-router-dom";
import CompaniesHeader from "./components/header";
import CompaniesListSearch from "./components/companies-list-search.tsx";
import { useInfiniteQuery } from "@tanstack/react-query";

import { useMemo } from "react";
import CompanyListItem from "@/routes/companies/companies-list/components/companyListItem.tsx";
import { InView } from "react-intersection-observer";
import CompanyListItemSkeleton from "@/routes/companies/companies-list/components/companyListItemSkeleton.tsx";
import { useAsync } from "react-async-hook";
import { pick } from "lodash";

export interface CompanyListData {
  city: string;
  company_id: string;
  company_logo_url: string;
  company_name: string;
  country: string;
  last_session_timestamp: string;
  lead_score: number;
  industry?: string;
}

async function getCompaniesList(
  searchParams: URLSearchParams,
  params: Readonly<Params<string>>,
  pageParam: number,
) {
  const filter = new URLSearchParams(searchParams);
  filter.set("page", String(pageParam));
  filter.set("page_size", String(10));

  if (params.domain) filter.set("domain", params.domain);
  if (params.company_details_id)
    filter.set("company_details_id", params.company_details_id);

  const res = await fetch(
    `${import.meta.env.VITE_APP_API}/api/companies_list?${filter}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    },
  );
  return await res.json();
}

function CompaniesListView() {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const {
    data: companiesList,
    fetchNextPage,
    hasNextPage,
    isLoading: loadingCompaniesList,
    refetch,
  } = useInfiniteQuery({
    queryKey: ["api", "companies_list", searchParams, pick(params, "domain")],
    queryFn: ({ pageParam }) =>
      getCompaniesList(searchParams, params, pageParam),
    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      const { isLast, current } = lastPage.pagination[0];
      return isLast ? undefined : current + 1;
    },
    getPreviousPageParam: (firstPage) => {
      const { current, isFirst } = firstPage.pagination[0];
      return isFirst ? undefined : current - 1;
    },
  });

  useAsync(async () => {
    await refetch();
  }, [searchParams]);

  const flatList =
    useMemo(
      () => companiesList?.pages.flatMap((p) => p.result),
      [companiesList],
    ) || [];

  // useEffect(() => {
  //   const firstCompanyId = companiesList?.pages[0]?.result[0]?.company_id;
  //
  //   if (firstCompanyId && !params?.company_details_id) {
  //     navigate({ pathname: `${firstCompanyId}`, search: location.search });
  //   }
  // }, [companiesList, navigate, params?.company_details_id]);

  return (
    <div className="xl:pl-72">
      <CompaniesHeader />
      <aside className="lg:fixed lg:bottom-0 lg:top-16 lg:w-96 lg:overflow-y-auto lg:border-r xl:left-72">
        <header className="flex items-center justify-between border-b bg-background p-2 xl:sticky xl:top-0 xl:z-10">
          <CompaniesListSearch />
        </header>
        <div
          // className="flex flex-col pb-[120px] gap-4 overflow-auto p-2 px-4 bg-[#f0f2f5] h-56 flex-grow md:h-full overflow-y-auto"
          className="flex min-h-[calc(100%_-_57px)] grow flex-col gap-3 bg-[#f0f2f5] pl-4 pr-3 pt-3"
          id="company-list"
        >
          {!loadingCompaniesList &&
            flatList.map((company: CompanyListData) => (
              <CompanyListItem key={company.company_id} company={company} />
            ))}
          <InView
            onChange={(inView) => {
              if (inView && hasNextPage) fetchNextPage();
            }}
            disabled={!hasNextPage}
          >
            {hasNextPage && <CompanyListItemSkeleton />}
          </InView>
        </div>
      </aside>
      <main className="lg:pl-96">
        <Outlet />
      </main>
    </div>
  );
}

export default CompaniesListView;
