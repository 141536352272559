import { SearchIcon } from "lucide-react";
import { Input } from "@/components/ui/input.tsx";

function CompaniesListSearch() {
  return (
    <div className="relative w-full">
      <SearchIcon className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
      <Input
        type="search"
        placeholder="Search..."
        className="rounded-lg bg-background pl-8 md:min-w-[200px] lg:min-w-[336px]"
      />
    </div>
  );
}

export default CompaniesListSearch;
