import HeroHeader from "./HeroHeader";
import Features from "./Features";
import { CTA_1, CTA_2 } from "../components/CallToAction";
import DataOverview from "./DataOverview";
import EasyImplementation from "./EasyImplementation";
import Layout from "../components/Layout";
import Customers from "../components/Customers";

const StartPage = () => {
  return (
    <Layout>
      <HeroHeader />
      <Features />
      <Customers />
      <CTA_1 />
      <DataOverview />
      <EasyImplementation />
      <CTA_2 />
    </Layout>
  );
};

export default StartPage;
