import { NavLink } from "react-router-dom";
import { DateTime } from "luxon";
import clsx from "clsx";
import { CompanyListData } from "../companies-list.loader.ts";

interface CompanyListItemProps {
  company: CompanyListData;
}

function CompanyListItem({ company }: CompanyListItemProps) {
  const getDaysLeftInGerman = (timestamp: string) => {
    const now = DateTime.now();
    const lastSession = DateTime.fromISO(timestamp);
    const diffInDays = Math.ceil(now.diff(lastSession, "days").days);

    if (diffInDays === 0) return "Heute";
    if (diffInDays === 1) return "Gestern";
    if (diffInDays < 7) return `Vor ${diffInDays} Tagen`;
    if (diffInDays < 30) return `Vor ${Math.floor(diffInDays / 7)} Wochen`;
    if (diffInDays < 365) return `Vor ${Math.floor(diffInDays / 30)} Monaten`;
    return `Vor ${Math.floor(diffInDays / 365)} Jahren`;
  };

  return (
    <NavLink
      to={{ pathname: company.company_id, search: location.search }}
      className={({ isActive }) =>
        clsx("group relative", { "is-active": isActive })
      }
    >
      <div className="absolute inset-y-0 -left-1.5 w-1.5 overflow-hidden rounded-l-full">
        <span
          className={clsx("absolute inset-0", {
            "bg-red-600": company.lead_score <= 10,
            "bg-orange-500":
              company.lead_score > 10 && company.lead_score <= 25,
            "bg-orange-400":
              company.lead_score > 25 && company.lead_score <= 40,
            "bg-yellow-300":
              company.lead_score > 40 && company.lead_score <= 55,
            "bg-green-400": company.lead_score > 55 && company.lead_score <= 70,
            "bg-green-500": company.lead_score > 70 && company.lead_score <= 85,
            "bg-green-600": company.lead_score > 85 && company.lead_score <= 95,
            "bg-green-700":
              company.lead_score > 95 && company.lead_score <= 100,
          })}
        />
        <span
          className="absolute inset-x-0 top-0 bg-gray-200"
          style={{ height: `${95 - company.lead_score}%` }}
        />
      </div>
      <div
        className={`relative flex min-h-[92px] cursor-pointer rounded-br-lg rounded-tr-lg bg-white p-3 hover:bg-[#f5faff] group-[.is-active]:bg-[#f5faff]`}
      >
        <div className="flex w-full gap-3">
          {/* Column A: Image */}
          <div
            className="h-[70px] w-[70px] flex-shrink-0 rounded bg-white bg-[length:85%] bg-center bg-no-repeat"
            style={{ backgroundImage: `url(${company.company_logo_url})` }}
          />
          {/* Column B: Company details */}
          <div className="flex min-w-0 flex-1 flex-col justify-start">
            {/* Days left */}
            <div className="text-right text-xs text-[#4d5666]">
              {getDaysLeftInGerman(company.last_session_timestamp)}
            </div>
            {/* Company name */}
            <div className="mt-1 truncate text-sm font-bold">
              {company.company_name}
            </div>
            {/* City and industry */}
            <div className="mt-1 flex items-center text-xs font-normal">
              <div className="mr-2 overflow-hidden text-ellipsis whitespace-nowrap pr-2 text-xs">
                <div className="text-2xs u-text-ellipsis flex items-center truncate rounded-full border bg-[#f1f5f980] px-1 text-gray-500">
                  <svg className="mr-[5px] h-4 w-4" viewBox="0 0 5 3">
                    <rect width="5" height="3" fill="#ffce00" />
                    <rect width="5" height="2" fill="#dd0000" />
                    <rect width="5" height="1" fill="#000" />
                  </svg>
                  <span className="u-text-ellipsis text-2xs">
                    {company.city}
                  </span>
                </div>
              </div>
              {company.industry && (
                <span className="max-w-[50%] overflow-hidden text-ellipsis whitespace-nowrap text-xs text-[#161616]">
                  {company.industry}
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </NavLink>
  );
}

export default CompanyListItem;
