import React, { useState, useEffect, useCallback } from "react";
import { Button, Card, Table, Label, RangeSlider } from "flowbite-react";
import { HiCheck, HiArrowRight } from "react-icons/hi";
import { Link } from "react-router-dom";
import RegisterModal from "../modal/RegisterModal";
import analytics from "../../analytics";

const pricingTiers = [
  { min: 10, max: 100, price: 0.95 },
  { min: 101, max: 250, price: 0.8 },
  { min: 251, max: 500, price: 0.7 },
  { min: 501, max: 1000, price: 0.6 },
  { min: 1001, max: 2000, price: 0.5 },
  { min: 2001, max: 5000, price: 0.4 },
];

const DynamicPricingPage = () => {
  const [sliderValue, setSliderValue] = useState(0);
  const [companies, setCompanies] = useState(10);
  const [price, setPrice] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const minCompanies = 10;
  const maxCompanies = 5001;

  // Convert slider value to number of companies (logarithmic scale)
  const sliderToCompanies = (value) => {
    return Math.round(
      Math.exp(
        Math.log(minCompanies) +
          ((Math.log(maxCompanies) - Math.log(minCompanies)) * value) / 100,
      ),
    );
  };

  // Convert number of companies to slider value
  const companiesToSlider = (companies) => {
    return Math.round(
      ((Math.log(companies) - Math.log(minCompanies)) /
        (Math.log(maxCompanies) - Math.log(minCompanies))) *
        100,
    );
  };

  useEffect(() => {
    const newCompanies = sliderToCompanies(sliderValue);
    setCompanies(newCompanies);

    if (newCompanies <= 5000) {
      let price = 0;
      for (const tier of pricingTiers) {
        if (newCompanies >= tier.min && newCompanies <= tier.max) {
          price = newCompanies * tier.price;
          break;
        }
      }
      setPrice(price);
    }
  }, [sliderValue]);

  const handleClick = useCallback(() => {
    analytics.track("register_modal_opened", {
      source: "pricing_page_button",
      companies: companies,
      price: price.toFixed(2),
    });
    setIsModalOpen(true);
  }, [companies, price]);

  return (
    <section className="bg-white dark:bg-gray-900">
      <div className="mx-auto max-w-screen-xl px-4 py-4 sm:pb-14 lg:px-6">
        <div className="flex flex-col gap-8 lg:flex-row">
          <Card className="flex flex-1 flex-col">
            <div className="flex-grow">
              <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                Preis
              </h5>
              <div
                className={`${companies > 5000 ? "text-3xl" : "text-5xl"} font-bold leading-[72px] dark:text-white`}
              >
                {companies > 5000
                  ? "Bitte Kontakt aufnehmen"
                  : `€${price.toFixed(2)}`}
                {companies <= 5000 && (
                  <span className="text-2xl font-normal text-gray-500 dark:text-white">
                    /Monat
                  </span>
                )}
              </div>
            </div>
            <p className="mb-4 font-normal text-gray-700 dark:text-gray-400">
              Für {companies > 5000 ? "5000+" : companies} eindeutige
              Identifikationen im Monat
            </p>

            <div className="mb-6">
              <RangeSlider
                id="companies-range"
                min={0}
                max={100}
                step={0}
                value={sliderValue}
                onChange={(e) => setSliderValue(Number(e.target.value))}
              />
            </div>

            <ul className="mb-6 space-y-4">
              {[
                "Staffelpreise abhängig von der Unternehmensidentifikationen",
                "Automatische Preisanpassung gemäß Nutzung",
                "Uneingeschränkter Zugang zu allen Features",
              ].map((item, index) => (
                <li key={index} className="flex items-start dark:text-white">
                  <HiCheck className="mr-2 mt-1 h-5 w-5 flex-shrink-0 text-webmetic_dark dark:text-webmetic_light" />
                  <span>{item}</span>
                </li>
              ))}
            </ul>

            {companies > 5000 ? (
              <Link to="/kontakt">
                <button className="btn flex w-full items-center justify-center">
                  Kontakt aufnehmen
                  <HiArrowRight className="ml-2 h-4 w-4" />
                </button>
              </Link>
            ) : (
              <button
                onClick={handleClick}
                className="btn mt-auto flex w-full items-center justify-center"
              >
                1 Monat kostenlos testen
                <HiArrowRight className="ml-2 h-4 w-4" />
              </button>
            )}
          </Card>

          <Card className="flex-1">
            <Table hoverable>
              <Table.Head>
                <Table.HeadCell>Anzahl</Table.HeadCell>
                <Table.HeadCell>Preis pro Unternehmen (€/Monat)</Table.HeadCell>
              </Table.Head>
              <Table.Body className="divide-y">
                {pricingTiers.map((tier, index) => (
                  <Table.Row
                    key={index}
                    className={
                      companies >= tier.min && companies <= tier.max
                        ? "bg-webmetic_light-50 dark:bg-webmetic_dark-800"
                        : ""
                    }
                  >
                    <Table.Cell>
                      {tier.min} - {tier.max}
                    </Table.Cell>
                    <Table.Cell>€{tier.price.toFixed(2)}</Table.Cell>
                  </Table.Row>
                ))}
                <Table.Row
                  className={
                    companies > 5000
                      ? "bg-webmetic_dark-50 dark:bg-webmetic_dark-800"
                      : ""
                  }
                >
                  <Table.Cell>5000+</Table.Cell>
                  <Table.Cell>Kontakt aufnehmen</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Card>
        </div>
        <RegisterModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          initialWebsite=""
        />
      </div>
    </section>
  );
};

export default DynamicPricingPage;
