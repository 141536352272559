import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu.tsx";
import { Button } from "@/components/ui/button.tsx";
import { CircleUser } from "lucide-react";
import { Link, useNavigate } from "react-router-dom";
import { DarkThemeToggle } from "flowbite-react";

function CompaniesHeader() {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  return (
    <div className="bg-background sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-6 border-b px-4 sm:px-6 lg:px-8">
      <h1 className="text-xl font-bold">Identifizierte Unternehmen</h1>
      <div className="w-full flex-1"></div>
      <DropdownMenu>
        <div className="mr-2 hidden sm:block">
          <DarkThemeToggle />
        </div>
        <DropdownMenuTrigger asChild>
          <Button variant="secondary" size="icon" className="rounded-full">
            <CircleUser className="h-5 w-5" />
            <span className="sr-only">User Menü</span>
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuLabel>Mein Konto</DropdownMenuLabel>
          <DropdownMenuSeparator />
          <Link to=".">
            <DropdownMenuItem>Einstellungen</DropdownMenuItem>
          </Link>
          <Link to="./account/settings/account_api">
            <DropdownMenuItem>API</DropdownMenuItem>
          </Link>
          <Link to=".">
            <DropdownMenuItem>Support</DropdownMenuItem>
          </Link>
          <DropdownMenuSeparator />
          <DropdownMenuItem onClick={handleLogout}>Logout</DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
}

export default CompaniesHeader;
