import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import Analytics from "./analytics";
import React, { useEffect } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import AuthProvider from "./provider/authProvider";

import StartPage from "./pages/startpage";
import LpDmexco from "./pages/landingpage";
import ContactPage from "./pages/contact";
import PricingPage from "./pages/pricing";
import FeaturesPage from "./pages/features";
import DataPrivacyPage from "./pages/dataprivacy";

import Datenschutzerklaerung from "./pages/legal/datenschutzerklaerung";
import Impressum from "./pages/legal/impressum";
import Agb from "./pages/legal/agb";

import Login from "./pages/authentication/Login";
import SendPassword from "./pages/authentication/SendPassword";
import dashboardLoader from "./routes/dashboard/dashboard.loader";
import Dashboard from "./routes/dashboard/dashboard.view";
import CompaniesFilterView from "./routes/companies/companies-filter/companies-filter.view";
import companiesFilterLoader from "./routes/companies/companies-filter/companies-filter.loader";
import CompaniesListView from "./routes/companies/companies-list/companies-list.view";
import CompanyDetails from "./routes/companies/company-details";
import companiesDetailsLoader from "./routes/companies/company-details/companies.loader";

// Settings
import AccountApiDetails from "./routes/account/ApiDetails";
import AccountSettings from "./routes/account/Settings";

const queryClient = new QueryClient({
  defaultOptions: {},
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <StartPage />,
  },
  {
    path: "/dmexco",
    element: <LpDmexco />,
  },
  {
    path: "/kontakt",
    element: <ContactPage />,
  },
  {
    path: "/impressum",
    element: <Impressum />,
  },
  {
    path: "/datenschutzerklaerung",
    element: <Datenschutzerklaerung />,
  },
  {
    path: "/agb",
    element: <Agb />,
  },
  {
    path: "/preise",
    element: <PricingPage />,
  },
  {
    path: "/funktionen",
    element: <FeaturesPage />,
  },
  {
    path: "/datenschutz",
    element: <DataPrivacyPage />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/send_password",
    element: <SendPassword />,
  },
  {
    path: "dashboard",
    loader: dashboardLoader,
    children: [
      { index: true, element: <Dashboard /> },
      {
        path: ":domain",
        element: <CompaniesFilterView />,
        loader: companiesFilterLoader(queryClient),
        children: [
          { index: true, element: <Navigate to="companies" replace /> },
          {
            path: "companies",
            element: <CompaniesListView />,
            children: [
              {
                path: ":company_details_id",
                element: <CompanyDetails />,
                loader: companiesDetailsLoader(queryClient),
              },
            ],
          },
        ],
        // children: [
        //   { index: true, element: <Navigate to="companies" replace /> },
        //   {
        //     path: "companies",
        //     element: <CompaniesListView />,
        //     children: [
        //       {
        //         path: ":company_details_id",
        //         element: <CompanyDetails />,
        //         loader: companiesDetailsLoader(queryClient),
        //       },
        //     ],
        //   },
        // ],
      },
    ],
  },
  {
    path: "account",
    element: <AccountSettings />,
    children: [
      {
        path: "api",
        element: <AccountApiDetails />,
      },
    ],
  },
]);

function App() {
  useEffect(() => {
    Analytics.page();
  }, []);
  return (
    //<ThemeProvider>
    <AuthProvider>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
      </QueryClientProvider>
    </AuthProvider>
    //</ThemeProvider>
  );
}

export default App;
