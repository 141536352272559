import { Navigate } from "react-router-dom";
import useUser from "@/hooks/useUser.ts";

function Dashboard() {
  const user = useUser()!;
  console.log({ user });
  return <Navigate to={`/dashboard/${user.website[0]}`} replace />;
}

export default Dashboard;
